<template>
  <div class="p-slide">
    <div class="text-size-h2 text-bold mb-m">{{ title }}</div>
    <div class="p-slide__row">
      <div class="p-slide__data">
        <div v-if="score">
          <p class="text-size-h4 text-bold">
            Добавь себе {{ score }} {{ scoreLabel }} и&nbsp;увеличь шансы
            на&nbsp;победу!
          </p>
          <p v-if="isJunior">
            Можно добавить к сфере, в которой есть хотя бы одно решенное задание
          </p>
          <p v-else>
            Баллы можно добавить к&nbsp;профилям I&nbsp;этапа, если решено
            хотя&nbsp;бы одно задание.
          </p>
        </div>
        <div v-else-if="pass">
          <p class="text-size-h4 text-bold">
            Ты&nbsp;можешь воспользоваться привилегией<br />
            и&nbsp;пройти в&nbsp;следующий этап
          </p>
        </div>
        <div v-else-if="finalPass">
          <p class="text-size-h4 text-bold">
            Ты&nbsp;можешь воспользоваться привилегией<br />
            и&nbsp;пройти в&nbsp;финал
          </p>
        </div>
      </div>
      <div class="p-slide__control">
        <BaseButton
          class="p-slide__btn"
          theme="white"
          @click.prevent="handleOpen"
          >{{ isJunior ? "Применить" : "Выбрать Профиль" }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { numCases } from "@/utils";
import ExtraScoresModal from "@/components/user/modals/ExtraScoresModal.vue";
export default {
  name: "SlideTypePrivilege",
  props: {
    title: String,
    score: {
      type: Number,
    },
    pass: Boolean,
    finalPass: Boolean,
    privilege: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isJunior() {
      return this.$store.getters["participant/isJunior"];
    },
    scoreLabel() {
      const { score } = this;
      return numCases(["балл", "балла", "баллов"], score);
    },
  },
  methods: {
    handleOpen() {
      const { privilege } = this;
      this.$modal.show(
        ExtraScoresModal,
        {
          privilege,
        },
        {
          adaptive: true,
          scrollable: true,
          minHeight: 200,
          height: "auto",
          width: 800,
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.p-slide {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;

  &__btn {
    min-width: 150px;
    margin-top: 15px;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__control {
    flex-grow: 0;
    padding-top: 5px;
  }

  &__data {
    margin-right: @grid-gutter-width;
    margin-top: 15px;
  }
}
</style>
