<template>
  <div class="banner-slide">
    <div class="banner-slide__content">
      <h4 class="text-size-h4 text-bold mb-s">{{ title }}</h4>
      <p
        v-if="description"
        class="text-size-m"
        v-html="description"></p>

      <BaseButton
        v-if="!success"
        class="mt-m"
        theme="white"
        @click="handleClick"
        >{{ buttonText }}</BaseButton
      >
      <div
        v-else
        class="text-size-m mt-m">
        <BaseIcon
          glyph="done"
          class="color-success"></BaseIcon
        >&nbsp;Партнер добавлен
      </div>
    </div>
    <div
      v-if="img"
      class="banner-slide__image">
      <BaseImage
        :src="img"
        :aspect-ratio="1 / 2"></BaseImage>
    </div>
  </div>
</template>

<script>
import { DRUGOE_DELO_PARTNER_ID } from "@/constants";
import { request } from "@/services/api";
export default {
  name: "BannerSlide",
  props: {
    description: {
      type: String,
    },
    title: String,
    img: String,
    id: [Number, String],
    buttonText: {
      type: String,
      default: "Подробнее",
    },
  },
  data() {
    return {
      success: false,
    };
  },
  methods: {
    async handleClick() {
      try {
        const { data } = await request({
          method: "POST",
          url: `/participant/partners`,
          data: {
            partner_id: DRUGOE_DELO_PARTNER_ID,
          },
        });
        if (data.partner) {
          this.$store.commit("participant/SET_STATE", {
            key: "partners",
            value: [...this.$store.state.participant.partners, data.partner],
          });
        }
        this.$notify({
          title: "Партнер добавлен",
          text: "Промокоды придут в течение недели!",
          type: "success",
          group: "base",
          duration: 10000,
        });
        this.success = true;
      } catch (error) {
        this.$notify({
          title: "Не удалось добавить партнера",
          text: error.message,
          type: "error",
          group: "base",
        });
      }
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "banner_click",
        eventLabel: this.id,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner-slide {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__content {
    width: 60%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 20px;
  }

  &__image {
    width: 40%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .media-min-sm({
    flex-direction: column;
    &__image {
      width: 100%;
      order: -1;
      margin-bottom: 20px;
    }

    &__content {
      width: 100%;
      padding-right: 0;
    }
  });
}
</style>
