<template>
  <div>
    <BaseLoadingBox
      v-if="pending"
      :pending="pending"
      theme="primary"
      class="index-slider-loader"></BaseLoadingBox>
    <Swiper
      v-else
      ref="slider"
      :options="options"
      class="index-slider">
      <SwiperSlide
        v-for="item in list"
        :key="item.id"
        class="index-slider__slide"
        :class="`slide-type--${item.slideType}`">
        <component
          :is="item.slideComponent"
          v-bind="item"></component>
      </SwiperSlide>
      <div
        slot="button-prev"
        class="swiper-button-prev"></div>
      <div
        slot="button-next"
        class="swiper-button-next"></div>
      <div
        slot="pagination"
        class="swiper-pagination"></div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import DefaultSlide from "./slides/Default.vue";
import Privilege from "./slides/Privilege.vue";
import BannerSlide from "./slides/BannerSlide.vue";
import PartnerSlide from "./slides/PartnerSlide.vue";
import "swiper/css/swiper.css";
const SLIDER_OPTIONS = {
  loop: false,
  slidesPerView: 1,
  speed: 600,
  autoHeight: true,
  spaceBetween: 30,
  autoplay: {
    delay: 6500,
    disableOnInteraction: true,
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
};
const SLIDE_TYPES = {
  default: "DefaultSlide",
  privilege: "Privilege",
  banner: "BannerSlide",
  partner: "PartnerSlide",
};
export default {
  name: "IndexSlider",
  components: {
    Swiper,
    SwiperSlide,
    DefaultSlide,
    Privilege,
    BannerSlide,
    PartnerSlide,
  },
  props: {
    slides: {
      type: Array,
    },
    pending: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    list() {
      const { slides } = this;
      return slides.map((n) => {
        const { type, ...rest } = n;
        const slideComponent = SLIDE_TYPES[type] || SLIDE_TYPES.default;
        return {
          ...rest,
          slideType: type,
          slideComponent,
        };
      });
    },
    options() {
      return {
        ...SLIDER_OPTIONS,
        enabled: this.slides?.length > 1,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.index-slider {
  &-loader {
    min-height: 230px !important;
    border-radius: @radius-m;
    position: relative;
    overflow: hidden;
    background-color: #dedede;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 5s infinite;
      content: "";
    }
  }
  &__slide {
    padding: 25px 50px;
    background-color: @link-blue;
    color: #fff;
    border-radius: @radius-m;
    min-height: 230px;

    & > div {
      min-height: 120px;
    }

    .media-max-sm({
      padding: 20px;
    });
  }

  .swiper-button-prev,
  .swiper-button-next {
    --swiper-navigation-size: 28px;
    color: #fff;
  }

  .media-min-sm({
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  });

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
