<template>
  <div class="banner-slide">
    <div class="banner-slide__content">
      <h4 class="text-size-h4 text-bold mb-s">{{ title }}</h4>
      <p
        v-if="description"
        class="text-size-m">
        {{ description }}
      </p>
      <BaseButton
        v-if="url"
        class="mt-m"
        tag="a"
        :href="url"
        target="_blank"
        rel="noopener noreferrer"
        theme="white"
        @click="handleClick"
        >Подробнее</BaseButton
      >
    </div>
    <div
      v-if="img"
      class="banner-slide__image">
      <BaseImage
        :src="img"
        :aspect-ratio="2 / 4"></BaseImage>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerSlide",
  props: {
    description: {
      type: String,
    },
    title: String,
    img: String,
    url: String,
    id: Number,
  },
  methods: {
    handleClick() {
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "banner_click",
        eventLabel: this.id,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.banner-slide {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__content {
    width: 60%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 20px;
  }

  &__image {
    width: 40%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .media-min-sm({
    flex-direction: column;
    &__image {
      width: 100%;
      order: -1;
      margin-bottom: 20px;
    }

    &__content {
      width: 100%;
      padding-right: 0;
    }
  });
}
</style>
